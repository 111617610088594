<template>
  <div class="card p-shadow-6">
    <div class="p-grid p-col-12 p-lg-12">
      <h1>Links Utéis</h1>
      <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
      <Divider />
      <Button
        label="Adicionar Link"
        icon="pi pi-plus"
        class="p-button-success p-button-outlined"
        @click="addNew()"
      />

      <Button
        label="Categorias dos Links"
        icon="pi pi-eye"
        class="p-button-success p-button-outlined p-ml-2"
        @click="$router.push(`/useful-links-categories`)"
      />
    </div>
      <Divider />
      <DataTable
        :value="linksList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        :paginator="true"
        :rows="20"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords} instalações"
        :sortOrder="1"
        editMode="row"
        dataKey="id"
        responsiveLayout="scroll"
      >
        <template #empty> Nada a mostrar. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <Column field="name" header="Nome" sortable filterField="name">
          <template #body="slotProps">
            <span class="p-column-title">Nome:</span>
            {{ slotProps.data.name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="categoryname"
          header="Categoria"
          filterField="categoryname"
          sortable
        >
          <template #body="slotProps">
            <span class="p-column-title">Categoria:</span>
            {{ getCategoryName(slotProps.data.categoryId) }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="linksCategories"
              :optionLabel="'name'"
              :optionValue="'name'"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          field="department"
          header="Departamento"
          sortable
          filterField="department"
        >
          <template #body="slotProps">
            <span class="p-column-title">Departamento:</span>
            {{ slotProps.data.department }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="departments"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>

        <Column field="url" header="Link" filterField="url">
          <template #body="slotProps">
            <span class="p-column-title">Link:</span>
            {{ slotProps.data.url }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>

        <Column
          field="active"
          header="Activa"
          sortable
          filterField="active"
          dataType="boolean"
        >
          <template #body="slotProps">
            <span class="p-column-title">Activa:</span>
            {{ slotProps.data.active == true ? "Sim" : "Não" }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: false, label: 'Não' },
                { value: true, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column :headerStyle="{ width: '50px' }">
          <template #body="slotProps">
            <Button
              v-tooltip.left="'Editar link'"
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success p-button-outlined p-mr-2"
              @click="editLink(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>

      <Dialog
        :visible.sync="showForm"
        :style="{ width: $isMobile() ? '100vw' : '40vw' }"
        :header="
          (linkToForm.action == 'new' ? 'Criar' : 'Actualizar') + ' Link'
        "
        :modal="true"
        :contentStyle="{ overflow: 'visible' }"
      >
        <form
          name="vacationsForm"
          class="p-col-12"
          @submit.prevent="saveNewCompetency"
        >
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-10">
              <span class="p-float-label">
                <InputText
                  type="text"
                  v-validate="'required'"
                  v-model="linkToForm.name"
                  v-bind:class="[
                    { 'p-invalid': errors.has('linkName') },
                    'form-control',
                  ]"
                  :id="'linkName'"
                  :name="'linkName'"
                />
                <label for="linkName">Nome do Link</label>
              </span>
              <small v-if="errors.has('linkName')" class="p-error" role="alert">
                Nome é obrigatório
              </small>
            </div>

            <div class="p-field p-col-12 p-md-2">
              <span class="p-float-label">
                <Dropdown
                  id="linkActive"
                  v-model="linkToForm.active"
                  name="linkActive"
                  v-validate="'required'"
                  v-bind:class="['form-control']"
                  :inputId="'linkActive'"
                  :options="[
                    { value: false, label: 'Não' },
                    { value: true, label: 'Sim' },
                  ]"
                  :optionLabel="'label'"
                  :optionValue="'value'"
                />
                <label for="linkActive">Activo</label>
              </span>
            </div>

            <div class="p-field p-col-12 p-md-12 p-mt-3">
              <span class="p-float-label">
                <InputText
                  type="text"
                  v-validate="{ url: { require_protocol: true } }"
                  v-model="linkToForm.url"
                  v-bind:class="[
                    { 'p-invalid': errors.has('linkUrl') },
                    'form-control',
                  ]"
                  :id="'linkUrl'"
                  :name="'linkUrl'"
                />
                <label for="linkUrl">URL</label>
              </span>
              <small v-if="errors.has('linkUrl')" class="p-error" role="alert">
                URL é obrigatório. Cuidado com o formato!!!
              </small>
            </div>

            <div class="p-field p-col-12 p-md-4 p-mt-3">
              <span class="p-float-label">
                <Dropdown
                  v-model="linkToForm.categoryId"
                  name="linkCategoryId"
                  v-validate="'required'"
                  v-bind:class="[
                    { 'p-invalid': errors.has('linkCategoryId') },
                    'form-control',
                  ]"
                  :inputId="'linkCategoryId'"
                  :options="getactiveCategories()"
                  :optionLabel="'name'"
                  :optionValue="'id'"
                />
                <label for="linkCategoryId">Categoria</label>
              </span>
              <small
                v-if="errors.has('linkCategoryId')"
                class="p-error"
                role="alert"
              >
                Categoria é obrigatório
              </small>
            </div>

            <div class="p-field p-col-12 p-md-8 p-mt-3">
              <span class="p-float-label">
                <MultiSelect
                  v-if="linkToForm.action == 'new'"
                  v-model="linkToForm.department"
                  name="linkDepartment"
                  :options="departments"
                  :filter="true"
                  display="chip"
                >
                </MultiSelect>
                <Dropdown
                  v-else
                  v-model="linkToForm.department"
                  name="linkDepartment"
                  v-bind:class="['form-control']"
                  :inputId="'linkDepartment'"
                  :showClear="true"
                  :options="departments"
                />
                <label for="linkDepartment">Departamento</label>
              </span>
            </div>

            <div class="p-field p-col-12 p-md-12 p-mt-3">
              <span class="p-float-label">
                <Textarea
                  type="text"
                  :autoResize="true"
                  rows="3"
                  v-model="linkToForm.comment"
                  v-bind:class="['form-control']"
                  :name="'linkComment'"
                />
                <label for="linkComment">Comentário</label>
              </span>
            </div>
          </div>
        </form>

        <template #footer>
          <Button
            :label="
              (linkToForm.action == 'new' ? 'Criar' : 'Actualizar') + ' Link'
            "
            icon="pi pi-check"
            class="p-button-text p-button-success"
            @click="saveForm()"
          />
          <Button
            label="Cancelar"
            icon="pi pi-times"
            class="p-button-text p-button-danger"
            @click="closeForm()"
          />
        </template>
      </Dialog>
    </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import utilitiesService from "../services/utilities.service";
import linksService from "../services/linksUseful.service";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "LinksUseful",
  data() {
    return {
      loading: true,
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        categoryname: { value: null, matchMode: FilterMatchMode.EQUALS },
        department: { value: null, matchMode: FilterMatchMode.CONTAINS },
        url: { value: null, matchMode: FilterMatchMode.CONTAINS },
        active: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      showForm: false,
      departments: [],
      linksCategories: [],
      linksList: [],
      linkToForm: {},
    };
  },
  async created() {
    await this.getDepartements();
    await this.getLinksCategories();
    await this.getLinks();
    this.loading = false;
  },
  methods: {
    getDepartements() {
      return utilitiesService.getDepartments().then((response) => {
        return (this.departments = response);
      });
    },
    getLinksCategories() {
      return linksService.getAllLinksCategories().then((response) => {
        return (this.linksCategories = response);
      });
    },
    getLinks() {
      return linksService.getAllLinks().then((response) => {
        return (this.linksList = response);
      });
    },
    getactiveCategories() {
      return this.linksCategories.filter((ctg) => {
        return ctg.active == true;
      });
    },
    addNew() {
      this.linkToForm = {
        action: "new",
        active: true,
      };
      this.showForm = true;
    },
    editLink(data) {
      this.linkToForm = data;
      this.showForm = true;
    },
    getCategoryName(categoryId) {
      let category = this.linksCategories.find((ctg) => ctg.id == categoryId);

      if (category === undefined) {
        return "";
      }

      return category.name;
    },
    closeForm() {
      this.showForm = false;
      this.$validator.pause();
      this.$validator.reset();
      this.linkToForm = {};
    },
    saveForm() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        if (this.linkToForm.action === "new") {
          return this.saveNewLink();
        } else {
          return this.updateLink();
        }
      });
    },
    saveNewLink() {
      let params = {
        categoryId: this.linkToForm.categoryId,
        name: this.linkToForm.name,
        url: this.linkToForm.url,
      };
      if (this.linkToForm.comment !== undefined) {
        params["comment"] = this.linkToForm.comment;
      }
      if (this.linkToForm.department !== undefined) {
        params["department"] = this.linkToForm.department;
      }

      if (this.linkToForm.department !== undefined) {
        return this.linkToForm.department.forEach((dpt, index) => {
          params["department"] = dpt;
          linksService.createLink(params).then((response) => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro",
                detail: "Ocorreu um erro ao criar o link",
                life: 3000,
              });
            }
            this.linksList.push(response);
            if (index + 1 == this.linkToForm.department.length) {
              this.closeForm();
              this.$toast.add({
                severity: "success",
                summary: "Link criado",
                detail: "O link foi criada com sucesso",
                life: 3000,
              });
              return response;
            }
          });
        });
      }

      return linksService.createLink(params).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao criar o link",
            life: 3000,
          });
        }
        this.linksList.push(response);
        this.closeForm();
        this.$toast.add({
          severity: "success",
          summary: "Link criado",
          detail: "O link foi criada com sucesso",
          life: 3000,
        });

        return response;
      });
    },
    updateLink() {
      if (this.linkToForm.department === null) {
        this.linkToForm.department = "";
      }

      if (this.linkToForm.active == true) {
        this.linkToForm.status = 1;
      } else {
        this.linkToForm.status = 0;
      }

      return linksService
        .updateLink(this.linkToForm.id, this.linkToForm)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao actualizado o link",
              life: 3000,
            });
          }
          this.closeForm();
          this.$toast.add({
            severity: "success",
            summary: "Link actualizado",
            detail: "O link foi actualizado com sucesso",
            life: 3000,
          });

          return response;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.p-column-filter {
  width: 100%;
}
</style>
